import React from "react";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import Section from "../../../components-v2/Base/Section";
import Heading from "../../../components-v2/Base/HeadingBuilder";
import heroBg from "../../../images/general-pos-og.png";
import LeadForm from "../../../components/Forms/LeadForm";
import { generalPosData } from "../../../data/forms/lead-form";
import { extractDefaultValues } from "../../../utils/form-utils";
import bg1 from "../../../images/svg/vector-10.svg";
import b1 from "../../../images/random/badge-101.png";
import b2 from "../../../images/random/badge-102.png";
import b3 from "../../../images/random/badge-103.png";
import b4 from "../../../images/random/badge-104.png";
import b5 from "../../../images/random/badge-105.png";
import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
import TopRestaurantsLogos from "../../../components-v2/TopRestaurantsLogos";
import Image from "../../../components/ImageQuerys/RandomImages";
import { HOSPITALITY_LPS } from "../../../utils/firebase/forms";
import reviews from "../../../data/reviews";

function GeneralPos({}) {
  return (
    <Layout
      className="non-fixed-width"
      isLanding
      useNewLPHeader
      phoneNumber="+18882246506"
      phoneText="(888) 224-6506"
    >
      <SEO
        title="Top-Rated Restaurant POS | SpotOn"
        description="Discover restaurant tech that works together. POS. Online ordering. Labor management. Waitlist & reservations. Reporting. And 24/7/365 support. Learn more."
        image={`https://spoton.com/${heroBg}`}
      />

      <Section className="grid grid-cols-1 lg:grid-cols-2 mt-20 md:mt-32 items-center gap-10">
        <div className="text-center lg:text-left">
          <Heading level={1} injectionType={1} withBlueSymbol>
            Top-Rated Restaurant POS
          </Heading>
          <div className="text-black-300 text-[20px] max-w-[400px] mt-10 mx-auto lg:mx-0 relative z-1">
            See why thousands of restaurants are using SpotOn’s fast and
            flexible <b>POS system.</b>
          </div>

          <div>
            <Heading
              withBlueSymbol={false}
              level={2}
              injectionType={4}
              className="text-black max-w-[800px] mx-auto mt-8 relative z-1 hidden md:block"
            >
              Committed to helping your business grow through innovation and
              unmatched customer support.
            </Heading>
            <div className="mt-8 md:mt-0 mx-auto flex flex-wrap gap-3 items-center justify-btween md:justify-between lg:justify-start xl:justify-between">
              <img
                className="max-w-[22%] md:max-w-[100px]"
                src={b1}
                alt="badge"
              />
              <img
                className="max-w-[22%] md:max-w-[100px]"
                src={b2}
                alt="badge"
              />
              <img
                className="max-w-[22%] md:max-w-[100px]"
                src={b3}
                alt="badge"
              />
              <img
                className="max-w-[22%] md:max-w-[100px]"
                src={b4}
                alt="badge"
              />
              <img
                className="hidden md:inline max-w-[22%] md:max-w-[100px]"
                src={b5}
                alt="badge"
              />
            </div>
          </div>
        </div>

        <div className="relative">
          <LeadForm
            className="relative z-[1]"
            plain
            noHeader
            formName="Lead Form"
            displayForm
            formData={generalPosData}
            initialValues={extractDefaultValues(generalPosData)}
            onValuesChange={() => null}
            leadType="restaurants"
            formValueForDataLayerEventName="industry"
            dataLayerEventNameAppendix="-lead"
            withLineOfBusinessOption={false}
            lineOfBusiness="Hospitality"
            productInterest=""
            collection={HOSPITALITY_LPS}
            thankYouPath="/lp/general-pos/thank-you"
          />
          <img
            src={bg1}
            alt="blue circle"
            className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
          />
        </div>
      </Section>

      <Section fullWidth className="bg-black md:mt-10">
        <div className="text-white grid grid-cols-1 lg:grid-cols-2 gap-y-10 gap-x-4 max-w-[1000px] mx-auto lg:py-40">
          <div className="text-center mx-auto lg:mx-0 lg:text-left">
            <Heading
              className="text-white"
              withBlueSymbol
              level={2}
              injectionType={1}
            >
              The POS system that works the way you work
            </Heading>

            <p className="text-white text-p max-w-[380px]">
              SpotOn Restaurant has the features, integrations, and tools you
              need to run your restaurant on your own terms. Whether you run a
              full-service or counter-service concept, our team will do all the
              heavy lifting to set you up with a solution that’s tailored to
              your specific needs. Add in a local representative in your
              community, on-site installation and training, and 24/7 support,
              and it’s no wonder more restaurants are choosing SpotOn.
            </p>
          </div>

          <div className="relative -mb-32 ">
            <Image
              imageName="general-pos-asset.png"
              alt="products"
              className="relative lg:absolute left-0 right-0 top-0"
            />
          </div>
        </div>
      </Section>

      <Section className="mt-40 xl:mt-48">
        <TestmonialReviews
          sectionData={{
            reviews,
            title: "What SpotOn customers are saying",
            subTitle: "Read some of our 1500+ five star customer reviews.",
          }}
        />
      </Section>

      <TopRestaurantsLogos
        desktopImageName="general-pos-logos-desktop.png"
        mobileImageName="general-pos-logos-mobile.png"
      />
    </Layout>
  );
}

export default GeneralPos;
